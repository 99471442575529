<template>
  <form class="form-withdraw">
    <div class="form-withdraw__content">
      <form-input-number
        v-model:value="amountValue"
        :label="getContent(walletContent, defaultLocaleWalletContent, 'withdraw.sumLabel') || ''"
        name="withdrawSum"
        :min="props.amountMin"
        :max="props.amountMax"
        :currency="activeAccount?.currency"
        :hint="fieldHint"
      />

      <component
        :is="getFieldComponent(field)"
        v-for="field in visibleFields"
        :key="field.key"
        v-model:value="withdrawFormData[field.key]"
        :type="fieldsType[field.key]?.type || 'text'"
        :label="field.labels[currentLocale?.code || ''] || field.labels.en"
        :name="field.key"
        :placeholder="field.hints[currentLocale?.code || ''] || field.hints.en"
        :options="getFieldOptions(field)"
        :is-required="withdrawFormRules[field.key]?.hasOwnProperty('required')"
        :hint="setError(field.key)"
        @input="fieldInputHandle(field)"
        @blur="v$[field.key]?.$touch()"
        @focus="onFocus(field.key)"
      />

      <button-base type="primary" size="md" :is-disabled="buttonDisabled" @click="getWithdraw">
        {{ getContent(walletContent, defaultLocaleWalletContent, 'withdraw.withdrawButton') }}
        {{ formatAmount(activeAccount?.currency, buttonAmount) }}
      </button-base>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import useVuelidate from '@vuelidate/core';
  import fieldsTypeMap from '~/maps/fieldsTypeMap.json';
  import type { CIWalletModal, IPaymentField } from '~/types';

  const props = defineProps<{
    amountMax: number;
    amountMin: number;
    fields: IPaymentField[];
    method: string;
  }>();

  const walletContent: Maybe<CIWalletModal> = inject('walletContent');
  const defaultLocaleWalletContent: Maybe<CIWalletModal> = inject('defaultLocaleWalletContent');
  const globalStore = useGlobalStore();
  const { alertsData, defaultLocaleAlertsData, currentLocale } = storeToRefs(globalStore);

  const walletStore = useWalletStore();
  const { showAlert } = useLayoutStore();
  const { closeModal } = useModalStore();
  const { activeAccount, requestPaymentMethodsRegion } = storeToRefs(walletStore);

  const { getContent, formatAmount } = useProjectMethods();

  const isSending = ref<boolean>(false);
  const amountValue = ref<string>(String(props.amountMin));

  const withdrawFormData = reactive<{ [key: string]: string }>({});
  props.fields.forEach((field: any) => {
    withdrawFormData[field.key] = field.value ?? undefined;
  });
  const fieldsType: any = fieldsTypeMap;
  const startRules = props.fields.reduce((currentRulesObj, currentField) => {
    const rulesArr: { rule: string; arguments?: string }[] = [];
    if (currentField.isRequired) rulesArr.push({ rule: 'required' });

    if (currentField.key === 'phone') {
      rulesArr.push({ rule: 'phone' });
    } else if (currentField.key === 'cpf_number') {
      rulesArr.push({ rule: 'cpf_number' }); // skeleton cfp_number rule harder
    } else if (currentField.regexp) {
      rulesArr.push({
        rule: 'regex',
        arguments: currentField.regexp,
      });
    }

    if (rulesArr.length) return { ...currentRulesObj, [currentField.key]: rulesArr };
    return currentRulesObj;
  }, {});

  const getFieldComponent = (field: IPaymentField): string => {
    const fieldComponent = fieldsType[field.key]?.component;
    if (fieldComponent) return fieldComponent;
    if (field.fieldType === 'select') return 'form-input-dropdown';
    if (field.fieldType === 'textarea') return 'form-input-textarea';
    return 'form-input-text';
  };

  const withdrawRules = ref<any>(startRules);
  const { getFormRules } = useProjectMethods();
  const withdrawFormRules = computed(() => getFormRules(withdrawRules.value));
  const serverFormErrors = ref<{ [key: string]: Maybe<string> }>({});
  const v$ = useVuelidate(withdrawFormRules, withdrawFormData);

  const getVisibleFields = (): IPaymentField[] => {
    const {
      public: { showWalletFilledFields },
    } = useRuntimeConfig();

    props.fields.forEach(field => {
      if (field.value !== null && v$.value[field.key]?.$invalid) v$.value[field.key].$touch();
    });

    if (showWalletFilledFields) {
      return props.fields;
    }

    return props.fields.filter(field => field.value === null || v$.value[field.key]?.$invalid);
  };
  const visibleFields = getVisibleFields(); // remove reactivity

  const onFocus = (fieldName: string): void => {
    if (serverFormErrors.value[fieldName]) {
      serverFormErrors.value[fieldName] = undefined;
    }
  };

  const setError = (fieldName: string): undefined | { variant: string; message: any } => {
    if (v$.value[fieldName]?.$error) {
      return {
        variant: 'error',
        message: v$.value[fieldName].$errors[0].$message,
      };
    }
    if (serverFormErrors.value[fieldName]) {
      return {
        variant: 'error',
        message: serverFormErrors.value[fieldName]?.[0],
      };
    }
    return undefined;
  };

  const fieldsStore = useFieldsStore();
  const getFieldOptions = (field: IPaymentField): any => {
    const platformOptions = fieldsStore.selectOptions[field.key];
    if (platformOptions?.length) return platformOptions;
    return (
      field.options?.map(option => {
        return { value: option.name, code: option.id };
      }) || []
    );
  };

  const buttonAmount = computed(() => {
    if (Number(amountValue.value) > props.amountMax) return props.amountMax;
    if (Number(amountValue.value) < props.amountMin) return props.amountMin;
    return Number(amountValue.value);
  });

  const fieldHint = computed(() => {
    const minContent = getContent(walletContent, defaultLocaleWalletContent, 'withdraw.minSum') || '';
    const maxContent = getContent(walletContent, defaultLocaleWalletContent, 'withdraw.maxSum') || '';
    const minAmountContent = `${minContent} ${formatAmount(activeAccount.value?.currency, props.amountMin)}`;
    const maxAmountContent = `${maxContent} ${formatAmount(activeAccount.value?.currency, props.amountMax)}`;

    return {
      variant: '',
      message: `${minAmountContent}, ${maxAmountContent}`,
    };
  });

  const buttonDisabled = computed(
    () =>
      v$.value.$invalid ||
      Number(amountValue.value) > (activeAccount.value?.withdrawalBalance ?? 0) ||
      Number(amountValue.value) < props.amountMin ||
      Number(amountValue.value) > props.amountMax ||
      isSending.value
  );

  const fieldInputHandle = (field: IPaymentField): void => {
    if (field.key === 'currencyWithdraw') {
      const currencyOption = field.options?.find(option => {
        return option.id === withdrawFormData.currencyWithdraw;
      });
      const currencyRegex = currencyOption?.regexp;

      withdrawRules.value = {
        ...withdrawRules.value,
        wallet_id: currencyRegex
          ? [
              { rule: 'required' },
              {
                rule: 'regex',
                arguments: currencyRegex,
              },
            ]
          : [{ rule: 'required' }],
      };
    }

    v$.value[field.key]?.$touch();
  };

  const getWithdraw = async (): Promise<void> => {
    if (buttonDisabled.value) return;

    const requestFormData = {
      ...withdrawFormData,
      phone: withdrawFormData.phone ? `+${withdrawFormData.phone}` : undefined,
    };

    isSending.value = true;
    const params = {
      method: props.method,
      currency: activeAccount.value?.currency || '',
      country: requestPaymentMethodsRegion.value,
      amount: Number(amountValue.value),
      accountId: activeAccount.value?.id || '',
      fields: requestFormData,
    };

    const { withdrawAccount } = useCoreWalletApi();
    useEvent('analyticsEvent', {
      event: 'walletSubmitForm',
      walletOperationType: 'withdraw',
    });

    try {
      await withdrawAccount(params);
      await closeModal('wallet');
      showAlert(
        alertsData.value?.wallet?.withdrawalProcessed || defaultLocaleAlertsData.value?.wallet?.withdrawalProcessed
      );
    } catch (err: any) {
      if (err.response?.status === 422) {
        serverFormErrors.value = err.data?.error?.fields;
      } else {
        showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
      }
      isSending.value = false;
    }
  };
</script>

<style src="~/assets/styles/components/form/withdraw.scss" lang="scss" />
