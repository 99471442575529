<template>
  <div class="balance">
    <div class="balance__rows">
      <div class="row">
        <div class="label">
          {{ getContent(walletContent, defaultLocaleWalletContent, 'balanceLabel') }}
        </div>

        <div class="value">{{ formatAmount(activeAccount?.currency, activeAccount?.balance) }}</div>
      </div>

      <div v-if="props.withdraw" class="row">
        <div class="label">{{ getContent(walletContent, defaultLocaleWalletContent, 'withdraw.withdrawLabel') }}</div>
        <div class="value">{{ formatAmount(activeAccount?.currency, activeAccount?.withdrawalBalance) }}</div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { CIWalletModal } from '~/types';

  const props = defineProps({
    withdraw: {
      type: Boolean,
      default: false,
    },
  });

  const walletContent: Maybe<CIWalletModal> = inject('walletContent');
  const defaultLocaleWalletContent: Maybe<CIWalletModal> = inject('defaultLocaleWalletContent');
  const walletStore = useWalletStore();
  const { activeAccount } = storeToRefs(walletStore);
  const { getContent, formatAmount } = useProjectMethods();
</script>

<style src="~/assets/styles/components/balance.scss" lang="scss" />
